// 公司信息
<template>
    <div class="system-detail baseBg"
         v-loading="isloading">
        <backUp></backUp>
        <div class="searchBar ofh flex-between-center">
            <h3 style="margin: 0;">{{softDetail.name || '234234'}}</h3>
        </div>
        <el-row>
            <el-col :span="24">
                <div class="infoBox">
                    <div class="cardBox">
                        <div class="cardTitle">软件版本信息</div>
                        <p><span class="label">系统地址：</span><span class="value">{{softDetail.systemUrl || ''}}</span></p>
                        <p><span class="label">备注：</span><span class="value">{{softDetail.memo || ''}}</span></p>
                    </div>
                </div>
            </el-col>
        </el-row>

        <el-row class="mt30">
            <el-col :span="24">
                <div class="infoBox ">
                    <div class="cardBox">
                        <div class="cardTitle">软件资料</div>
                        <el-row class="softWareBox">
                            <el-col :span="12">
                                <el-tabs v-model="activeName"
                                         type="card"
                                         @tab-click="handleClick">

                                    <el-tab-pane v-for="(item,index) in moduleList"
                                                 :key="index"
                                                 :label="item.name"
                                                 :name="item.name">
                                        <comFileUploadList :form="{title:''}"
                                                           fileBoxHeight="200px"
                                                           :showNumber="false"
                                                           :uploadData="{resourceId:softDetail.id,resourceType:item.value}"
                                                           :showBlock="true"
                                                           :neddLoadFile="true"
                                                           :showUpBtn="true"></comFileUploadList>
                                    </el-tab-pane>

                                </el-tabs>
                            </el-col>
                        </el-row>

                    </div>
                </div>
            </el-col>
        </el-row>

    </div>
</template>
<script>
import commonAPI from "@/api/commonAPI.js";
import backUp from "@/components/backUp";
import comFileUploadList from "@/components/upload/comFileUploadList";
export default {
    name: "comSoftwareDetail",
    props: {},
    watch: {},
    data() {
        return {
            activeName: "部署手册",
            softDetail: {},
            isloading: false,
            moduleList: [],
        };
    },
    components: {
        backUp,
        comFileUploadList,
    },
    mounted() {
        this.softDetail = JSON.parse(this.$route.query.data);
        this.getDictItemByModule("systemVersion");
    },
    methods: {
        //获取模板字典
        getDictItemByModule(code) {
            this.loading = true;
            commonAPI
                .getModuleCode(code)
                .then((res) => {
                    this.loading = false;
                    this.moduleList = res.content;
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        handleClick() {},
    },
    computed: {},
};
</script>
<style scoped>
.cardBox {
    padding-bottom: 10px;
    margin-bottom: 0px;
}
.mt30 {
    margin-top: 30px;
}
.zzBox {
    padding: 5px 15px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #0092ff;
    color: #fff;
    margin-top: 10px;
    margin-right: 15px;
}
.fileZzLine {
    width: 100%;
    display: inline-block;
    border-bottom: 1px dashed #ddd;
}
.softWareBox {
    padding: 20px;
}
</style>